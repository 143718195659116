<template>
  <div>
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="fullPage"></loading>
    <div class="row justify-content-md-center">

      <!-- Sidebar -->
      <div class="col-12">
        <filter-side-bar>
          <template slot="ContentSideBar">
            <form id="processes-list-filter" ref='processesListFilter' v-on:submit.prevent="applyFilter()">
              <h5 class="pb-4">Filtrar por:</h5>

              <!-- Moedas -->
              <!-- <h6 class="mb-0 mt-4">Moeda de origem</h6>
              <div class="row pb-3">
                <div class="col-md-6"> -->
                  <!-- 'USD', Dólar dos Estados Unidos -->
                  <!-- <div class="md-form mb-0 row">
                    <div class="col-md-9">Dólar (US)</div>
                    <div class="col-md-3 text-right no-padding">
                      <label class="ui-switch no-margin small switch-solid switch-warning" :class="{disableLabel: disable.usd}">
                        <input id="moeda_usd" value="2" :disabled="disable.usd" v-on:click="validaFiltro" type="checkbox" ref="moeda_usd" :checked="filters.filUsd"><span></span></label>
                    </div>
                  </div> -->
                  <!-- 'EUR', Euro -->
                  <!-- <div class="md-form mb-0 row">
                    <div class="col-md-9">Euro</div>
                    <div class="col-md-3 text-right no-padding">
                      <label class="ui-switch no-margin small switch-solid switch-warning" :class="{disableLabel: disable.eur}">
                        <input id="moeda_eur" value="1" :disabled="disable.eur" v-on:click="validaFiltro" type="checkbox" ref="moeda_eur" :checked="filters.filEur"><span></span>
                      </label>
                    </div>
                  </div> -->
                  <!-- 'GBP', Libra Esterlina -->
                  <!-- <div class="md-form mb-0 row">
                    <div class="col-md-9">Libra</div>
                    <div class="col-md-3 text-right no-padding">
                      <label class="ui-switch no-margin small switch-solid switch-warning" :class="{disableLabel: disable.gbp}">
                        <input id="moeda_gbp" value="3" :disabled="disable.gbp" v-on:click="validaFiltro" type="checkbox"  ref="moeda_gbp" :checked="filters.filGbp"><span></span>
                      </label>
                    </div>
                  </div> -->
                  <!-- 'AUD', Dólar australiano -->
                  <!-- <div class="md-form mb-0 row">
                    <div class="col-md-9">Dólar (AU)</div>
                    <div class="col-md-3 text-right no-padding">
                      <label class="ui-switch no-margin small switch-solid switch-warning" :class="{disableLabel: disable.aud}">
                        <input id="moeda_aud" value="3" :disabled="disable.aud" v-on:click="validaFiltro" type="checkbox"  ref="moeda_aud" :checked="filters.filAud"><span></span>
                      </label>
                    </div>
                  </div> -->
                  <!-- 'CAD', Dólar canadense -->
                  <!-- <div class="md-form mb-0 row">
                    <div class="col-md-9">Dólar (CAN)</div>
                    <div class="col-md-3 text-right no-padding">
                      <label class="ui-switch no-margin small switch-solid switch-warning" :class="{disableLabel: disable.cad}">
                        <input id="moeda_cad" value="3" :disabled="disable.cad" v-on:click="validaFiltro" type="checkbox"  ref="moeda_cad" :checked="filters.filCad"><span></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-md-6"> -->
                  <!-- 'CHF', Franco suíço -->
                  <!-- <div class="md-form mb-0 row">
                    <div class="col-md-9">Franco suíço</div>
                    <div class="col-md-3 text-right no-padding">
                      <label class="ui-switch no-margin small switch-solid switch-warning" :class="{disableLabel: disable.chf}">
                        <input id="moeda_chf" value="3" :disabled="disable.chf" v-on:click="validaFiltro" type="checkbox"  ref="moeda_chf" :checked="filters.filChf"><span></span>
                      </label>
                    </div>
                  </div> -->
                  <!-- 'DKK', Coroa dinamarquesa -->
                  <!-- <div class="md-form mb-0 row">
                    <div class="col-md-9">Coroa (DEN)</div>
                    <div class="col-md-3 text-right no-padding">
                      <label class="ui-switch no-margin small switch-solid switch-warning" :class="{disableLabel: disable.dkk}">
                        <input id="moeda_dkk" value="2" :disabled="disable.dkk" v-on:click="validaFiltro" type="checkbox" ref="moeda_dkk" :checked="filters.filDkk"><span></span></label>
                    </div>
                  </div> -->
                  <!-- 'JPY', Iene -->
                  <!-- <div class="md-form mb-0 row">
                    <div class="col-md-9">Iene</div>
                    <div class="col-md-3 text-right no-padding">
                      <label class="ui-switch no-margin small switch-solid switch-warning" :class="{disableLabel: disable.eur}">
                        <input id="moeda_euro" value="1" :disabled="disable.eur" v-on:click="validaFiltro" type="checkbox" ref="moeda_euro" :checked="filters.filEur"><span></span>
                      </label>
                    </div>
                  </div> -->
                  <!-- 'NOK', Coroa norueguesa -->
                  <!-- <div class="md-form mb-0 row">
                    <div class="col-md-9">Coroa (NOR)</div>
                    <div class="col-md-3 text-right no-padding">
                      <label class="ui-switch no-margin small switch-solid switch-warning" :class="{disableLabel: disable.gbp}">
                        <input id="operation_nac" value="3" :disabled="disable.gbp" v-on:click="validaFiltro" type="checkbox"  ref="moeda_gbp" :checked="filters.filNok"><span></span>
                      </label>
                    </div>
                  </div> -->
                  <!-- 'SEK', Coroa sueca -->
                  <!-- <div class="md-form mb-0 row">
                    <div class="col-md-9">Dólar (CAN)</div>
                    <div class="col-md-3 text-right no-padding">
                      <label class="ui-switch no-margin small switch-solid switch-warning" :class="{disableLabel: disable.cad}">
                        <input id="operation_nac" value="3" :disabled="disable.cad" v-on:click="validaFiltro" type="checkbox"  ref="moeda_gbp" :checked="filters.filCad"><span></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div> -->

              <h6 class="mb-0 mt-4">{{ $t('kpi.volume.period') }}</h6>
              <div class="row">
                <div class="py-1 col-md-6">
                  <div class="md-form mb-0">
                    <div class="input-group-icon input-group-icon-right mb-0">
                      <div class="md-form mb-0">
                        <span class="input-icon input-icon-right">
                          <i class="ti-calendar"></i>
                        </span>
                        <input data-index="2" :title="this.$i18n.t('form.process.period_time_arrival')"
                               id="begin_date" ref="begin_date" readonly
                               class="form-date md-form-control" type="text"
                               :value="$util.formatDate(filters.PeriodoInicio, undefined,null)">
                        <label :class="{'active': filters.PeriodoInicio}">{{ $t('filter.Start') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="py-1 col-md-6">
                  <div class="md-form mb-0">
                    <div class="input-group-icon input-group-icon-right mb-4">
                      <div class="md-form mb-4">
                        <span class="input-icon input-icon-right">
                          <i class="ti-calendar"></i>
                        </span>
                        <input data-index="2" :title="this.$i18n.t('form.process.period_time_arrival')"
                               id="end_date" ref="end_date" readonly class="form-date md-form-control"
                               type="text" :value="$util.formatDate(filters.PeriodoFim, undefined, null)">
                        <label :class="{'active': filters.PeriodoFim}">{{ $t('filter.End') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="py-1 text-center">
                <div class="md-form mb-0">
                  <div class="row">
                    <div class="col-6">
                      <button type="submit" class="btn btn-primary btn-fill btn-block mr-2 ">{{ $t('filter.apply') }}
                      </button>
                    </div>
                    <div class="col-6">
                      <button type="button" v-on:click="resetFilter" class="btn btn-cancel btn-fill btn-block mr-2 ">{{
                        $t('filter.reset') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </filter-side-bar>
      </div>

      <!-- Filtros ativos -->
      <div class="col-12 mb-3">
        <filter-container :activeFilterList="activeFilterList" />
      </div>

      <!-- Tabela de cotações -->
      <div class="col-12 col-sm-12">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">

                <!-- Table -->
                <!--<div v-on:click="toggleColumn">Config</div>-->
                <div class="table-responsive ">
                  <vuetable ref="vuetable"
                            :noDataTemplate="this.$i18n.t('noting to result')"
                            :api-url="quotesEndpoint"
                            :fields="vTableFields"
                            :sort-order="sortOrder"
                            :css="css.table"
                            pagination-path="pagination"
                            :per-page="20"
                            :multi-sort="multiSort"
                            :append-params="filter"
                            multi-sort-key="shift"
                            :http-options="httpOptions"
                            track-by="id"
                            @vuetable:pagination-data="onPaginationData"
                            @vuetable:loading="onLoading"
                            @vuetable:loaded="onLoaded"
                            @vuetable:load-error="handleLoadError"
                            @vuetable:cell-clicked="onRowClicked">
                    <template slot="actions" slot-scope="props">
                      <div class="custom-actions">
                        <a class="btn btn-link"
                           v-bind:href="mountLink('QuoteEdit', props.rowData)">
                          <i class="text-primary  ti-pencil font-20"></i>
                        </a>
                        <a class="btn btn-link"
                           v-on:click="onDelete(props.rowData)">
                          <i class="text-primary  ti-trash font-20"></i>
                        </a>
                      </div>
                    </template>
                  </vuetable>

                </div>
                <vuetable-pagination ref="pagination"
                                     :css="css.pagination"
                                     @vuetable-pagination:change-page="onChangePage"
                ></vuetable-pagination>
                <!-- END Table -->
                <!-- <router-link :to="{name: 'QuoteNew'}" class="btn  btn-link  pull-right">
                  <i class="text-primary ft-plus font-20"></i>
                </router-link> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vuetable, { VuetablePagination } from 'vuetable-2'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import FilterSideBar from '@/components/FilterSideBar.vue'
import FilterContainer from '@/components/FilterContainer'

import { util } from '@/utils/utils.js'
import moment from 'moment'
import dayjs from 'dayjs'

import CurrencyService from '@/services/CurrencyService'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

export default {
  name: 'CurrencyQuoteList',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('basic.currencyquote.title') + ' - %s'
    }
  },
  data () {
    return {
      currencyToGet: [
        usd => 'USB',
        eur => 'EUR',
        gbp => 'GBP'
      ],
      currencyList: [],
      isLoading: false,
      fullPage: true,
      currentDate: null,
      currentPtax: null,
      currentOpening: null,
      previousDate: null,
      previousPtax: null,
      previousOpening: null,
      quotesEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/currency/ptax/history',
      multiSort: true,
      activeFilterList: {
        currency: [],
        begin_date: null,
        end_date: null
      },
      filter: {},
      fieldsTable: [
        {
          name: 'data_date',
          // title: this.$i18n.t('form.goal.year'),
          title: 'Data',
          sortField: 'data_date',
          formatter: (value) => this.$util.formatDate(value, undefined, ' - ')
        },
        {
          name: 'idmoeda_origem',
          title: 'Moeda',
          sortField: 'idmoeda_origem',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'fator',
          title: 'Fator',
          sortField: 'fator',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'idconversao_moeda',
          title: 'Tipo',
          sortField: 'idconversao_moeda',
          formatter: (value) => this.$util.formatEmpty(value)
        }
      ],
      sortOrder: [
        { field: 'data', direction: 'desc' }
      ],
      httpOptions: {
        headers: {
          Authorization:
            'Bearer ' + global.instanceApp.$Cookie.get('_userAuth_')
        }
      },
      css: {
        table: {
          tableClass: 'table table-hover align-middle table-clickable',
          loadingClass: 'loading',
          ascendingIcon: 'ti-angle-double-up',
          descendingIcon: 'ti-angle-double-down',
          handleIcon: 'glyphicon glyphicon-menu-hamburger'
        },
        pagination: {
          infoClass: 'pull-left',
          wrapperClass: 'vuetable-pagination text-center',
          activeClass: 'page-item active',
          disabledClass: 'disabled',
          pageClass: 'btn btn-border',
          linkClass: 'btn btn-border',
          icons: {
            first: '',
            prev: '',
            next: '',
            last: ''
          }
        }
      },
      disable: {
        usd: false, // Dólar americano
        eur: false, // Euro
        gbp: false, // Libra Esterlina
        aud: false, // Dólar australiano
        cad: false, // Dólar canadense
        chf: false, // Franco suíço
        dkk: false, // Coroa dinamarquesa
        jpy: false, // Iene
        nok: false, // Coroa norueguesa
        sek: false // Coroa sueca
      }
    }
  },
  components: {
    Loading,
    FilterSideBar,
    FilterContainer,
    Vuetable,
    VuetablePagination
  },
  // Apply filters to local filter
  beforeMount () {},
  mounted () {
    this.$store.dispatch('SetShowFilter', true)
    this.$store.dispatch('setShowSearch', false)

    this.$jQuery('#begin_date, #end_date').datepicker({
      language: 'pt-BR',
      calendarWeeks: true,
      autoclose: true,
      templates: {
        leftArrow: '<i class="ti-angle-left"></i>',
        rightArrow: '<i class="ti-angle-right"></i>'
      }
    })

    this.currencyToGet.map(value => this.getQuotes(value))
  },
  methods: {
    onLoading () {
      this.isLoading = true
    },
    onLoaded () {
      this.isLoading = false
    },
    mountLink (itemName, object) {
      // return this.$router.resolve({ name: itemName, params: { id_employee: object.idEmployee, year: object.year } }).href
      return null
    },
    async getQuotes (curr) {
      let _this = this
      _this.isLoading = true
      await CurrencyService.getCurrencyQuotes(curr).then(res => {
        let data = res.data
        if (data['result']) {
          this.currentDate = data['dataAtual'] ? moment(data['dataAtual']).format('DD/MM/YYYY') : 'Não disponível'
          this.currentOpening = data['aberturaDia'] ? util.formatToPrice(data['aberturaDia']) : '-'
          this.currentPtax = data['ptaxDia'] ? util.formatToPrice(data['ptaxDia']) : '-'
          this.previousDate = data['dataAnterior'] ? moment(data['dataAnterior']).format('DD/MM/YYYY') : 'Não disponível'
          this.previousOpening = data['aberturaAnterior'] ? util.formatToPrice(data['aberturaAnterior']) : '-'
          this.previousPtax = data['ptaxAnterior'] ? util.formatToPrice(data['ptaxAnterior']) : '-'
        }
        _this.isLoading = false
      }).catch(() => {
        _this.isLoading = false
      })
    },
    handleLoadError (response) {
    },
    // Loader
    formatDateTime (value, locale, emptyValue) {
      emptyValue = emptyValue || null

      if (!value) {
        return emptyValue
      }

      locale = locale || 'pt-BR'

      let options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      }

      if (!value) {
        return null
      }

      let date = new Date(value)

      if (locale === 'pt-BR') {
        options.timeZone = 'America/Sao_Paulo'

        return date.toLocaleString('pt-BR', options)
      } else {
        options.timeZone = 'America/New_York'

        return date.toLocaleString('en-US', options)
      }
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    onRowClicked (row) {

    },
    buildFilters () {
      // Operation Filter
      this.filter.currency = []
      if (this.filters.moeda_usd) {
        this.filter.operation.push(this.filters.moeda_usd)
      }
      if (this.filters.moeda_eur) {
        this.filter.operation.push(this.filters.moeda_eur)
      }
      if (this.filters.moeda_gbp) {
        this.filter.operation.push(this.filters.moeda_gbp)
      }

      this.filter.begin_date = this.filters.PeriodoInicio
      this.filter.end_date = this.filters.PeriodoFim
      this.filter.currency = (this.filters.filCurrency) ? this.filters.filCurrency.map(x => (x.id)) : []
    },
    applyFilter () {
      this.currencyList = []
      let dayParse = 'DD/MM/YYYY'
      this.filters.PeriodoInicio = dayjs(this.$refs.begin_date.value, dayParse).isValid()
        ? dayjs(this.$refs.begin_date.value, dayParse).toISOString() : null
      this.filters.PeriodoFim = dayjs(this.$refs.end_date.value, dayParse).isValid()
        ? dayjs(this.$refs.end_date.value, dayParse).toISOString() : null

      this.buildFilters()
      this.$store.dispatch('setAppliedFilter', this.filters)
      this.hideQuickSidebar()
      this.$refs.vuetable.refresh()
    },
    resetFilter (event) {
      event.preventDefault()
      this.hideQuickSidebar()

      this.filters.PeriodoInicio = null
      this.filters.PeriodoFim = null
      this.filters.filCurrency = []

      this.$store.dispatch('setAppliedFilter', this.filters)
      this.buildFilters()
      this.$refs.vuetable.refresh()
    },
    validaFiltro () {
      // let contOp = 0

      // contOp = this.$refs.moeda_usd.checked ? (contOp + 1) : contOp
      // contOp = this.$refs.moeda_eur.checked ? (contOp + 1) : contOp
      // contOp = this.$refs.moeda_gbp.checked ? (contOp + 1) : contOp
      // contMo = this.$refs.moeda_cad.checked ? (contMo + 1) : contMo
      // contMo = this.$refs.moeda_chf.checked ? (contMo + 1) : contMo
      // contMo = this.$refs.moeda_dkk.checked ? (contMo + 1) : contMo
      // contMo = this.$refs.moeda_jpy.checked ? (contMo + 1) : contMo
      // contMo = this.$refs.moeda_nok.checked ? (contMo + 1) : contMo
      // contMo = this.$refs.moeda_sek.checked ? (contMo + 1) : contMo

      // this.disable.usd = false
      // this.disable.eur = false
      // this.disable.gbp = false
      // this.disable.cad = false
      // this.disable.chf = false
      // this.disable.dkk = false
      // this.disable.jpy = false
      // this.disable.nok = false
      // this.disable.sek = false

      // if (contOp === 1) {
      //   switch (true) {
      //     case this.$refs.moeda_usd.checked:
      //       this.disable.usd = true
      //       break
      //     case this.$refs.moeda_eur.checked:
      //       this.disable.eur = true
      //       break
      //     case this.$refs.moeda_gbp.checked:
      //       this.disable.gbp = true
      //       break
      //   }
      // }
    },
    hideQuickSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    }
  },
  computed: {
    vTableFields () {
      return this.fieldsTable
    },
    filters () {
      return this.$store.getters.aplliedFilters || null
    },
    refreshFilter () {
      return this.$store.getters.getRefreshFilter || null
    }
  },
  watch: {
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.applyFilter()
        this.$store.dispatch('setRefreshFilter', false)
      }
    }
  }
}
</script>

<style scoped>
  .currency {
    color: #84754E;
    font-weight: 600;
  }
  .title {
    min-height: 0;
    font-size: 10px;
    color: #A5A4A4;
  }
  .ptax-value {
    font-size: 25px;
    font-weight: 600;
    color: #383838;
  }
  .read-date, .currency-value-opening {
    font-size: 13px;
    font-weight: 600;
  }
  .read-date {
    color: #F3614E;
  }
  .currency-value-opening {
    color: #71CC74;
  }
</style>
